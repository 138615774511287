import {createRouter, createWebHashHistory, createWebHistory} from 'vue-router'
import Index from '@/views/mnc_index.vue'
import Advisory_database from "@/views/advisory_database.vue";
import Mnc_subscription from "@/views/mnc_subscription.vue";
import News_content from "@/views/news_content.vue";

const routes = [
  {
    path: '/',
    name: 'home',
    component: Index
  },
  {
    path: '/advisory',
    name: 'advisory',
    component: Advisory_database
  },
  {
    path: '/subscription',
    name: 'subscription',
    component: Mnc_subscription
  },
  {
    path: '/content/:id',
    name: 'content',
    component: News_content,
  },
]

const router = createRouter({
  // history: createWebHistory(),
  history: createWebHashHistory(),
  routes
})

export default router
