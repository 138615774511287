<template>
  <div class="page_background">
    <div class="form_favicon">
<!--      <img :src="requireImg('assets/logo.png')" />-->
      <img src="@/assets/favicon.png" alt="favicon" class="left_nav_title_img" />MNC情报数据库
    </div>
    <div class="form_container">
      <div class="flex_def form_content">
        <div style="width: 420px">
          <div class="flex_def" style="align-items: baseline">
            <p style="font-size: 30px; font-weight: 600; line-height: 4rem; margin-bottom: 20px">登录</p>
          </div>
          <el-form ref="signupFormRef" :model="ruleForm" :rules="rules" class="demo-ruleForm" status-icon
            style="font-size: 0.8rem;position:relative;">
            <span class="a_signup" @click="user_tab = 'sign_up'" v-show="!if_ldap">
              没有账号，前往注册
            </span>
            <el-form-item :label="(!if_ldap) ? '邮箱或手机号' : 'OA账号'" prop="email" style="display: block">
              <el-input v-model.trim="ruleForm.email" :placeholder="!if_ldap ? '请输入邮箱或者手机号': '请输入用户名'" />
            </el-form-item>

            <el-form-item label="密码" prop="pass" style="display: block">
              <el-input v-model.trim="ruleForm.pass" autocomplete="off" maxlength="20" placeholder="请输入密码"
                type="password" @keyup.enter="submitForm(signupFormRef)" />
            </el-form-item>

            <el-form-item class="form_btns">
              <el-button class="sub_btn" type="primary" @click="submitForm(signupFormRef)" :loading="btn_loading"
                id="submit_btn">
                登录
              </el-button>
            </el-form-item>
            <div class="flex_def flex_between">
              <span class="a_forget" @click="user_tab = 'forget_password'" v-show="!if_ldap">
                忘记密码
              </span>
              <span class="a_tip" v-show="!if_ldap">
                DrugFlow用户可直接登录
              </span>
            </div>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive } from 'vue'
import { requireImg } from '@/components/common/utils.js'
import {signin_api, get_csrf_token, get_token} from '@/api/user.js'
import { isEmail, isMobile } from '@/utils/validate'
import {ElMessage} from "element-plus";
import useStatusStore from "@/store";
import {storeToRefs} from "pinia";
import App_frame from "@/components/layout/app_frame.vue";

const store = useStatusStore()
const { user_tab, login_dialog } = storeToRefs(store)

const btn_loading = ref(false)
const if_ldap = ref(false)

const loadApi = async () => {
  if (import.meta.env.VITE_APP_ENV === 'ldap') {
    if_ldap.value = true
  }
}
loadApi()
const isemail = (rule, value, callback) => {
  if (if_ldap.value) {
    if (value === '') {
      callback(new Error('请输入OA账号'))
    } else {
      callback()
      return true
    }
  } else {
    if (value === '') {
      callback(new Error('请输入邮箱或者手机号'))
    } else if (!isEmail(value) && !isMobile(value)) {
      callback(new Error('请输入正确的邮箱或者手机号'))
    } else {
      callback()
      return true
    }
  }
}

const validatePass = (rule, value, callback) => {
  if (value === '') {
    callback(new Error('请输入密码'))
  } else {
    callback()
  }
}

const ruleForm = reactive({
  email: '',
  pass: ''
})

const rules = reactive({
  email: [{ validator: isemail, trigger: 'blur', required: true }],
  pass: [{ validator: validatePass, trigger: 'blur', required: true }]
})

const signupFormRef = ref()

const submitForm = formName => {
  if (!formName) return
  btn_loading.value = true
  formName.validate(valid => {
    if (valid) {
      if(if_ldap){
        let email = ''
        if (!isMobile(ruleForm.email)){
          email = ruleForm.email
        }
        const formData = new FormData();
        formData.append('grant_type', 'password');
        formData.append('username', ruleForm.email);
        formData.append('password', ruleForm.pass);
        formData.append('scope', '');
        formData.append('client_id', '');
        formData.append('client_secret', '');
        get_token(formData)
            .then(res=>{
              btn_loading.value = false
              login_dialog.value = false
              window.location.reload()
              localStorage.setItem('access_token', res.data.access_token)
              if(email){
                localStorage.setItem('email', email)
              }
            })
            .catch((err)=>{
              if (err.response.data.detail) {
                ElMessage.error(parse_err(err.response.data.detail))
              } else {
                ElMessage.error(err.response.data.message)
              }
              btn_loading.value = false
            })
      } else {
        let email = ''
        const formData = new FormData()
        if (isMobile(ruleForm.email)) {
          formData.append('phone', ruleForm.email)
          formData.append('email', 'drugflow@none.com')
        } else {
          email = ruleForm.email
          formData.append('email', ruleForm.email)
          formData.append('phone', '123456789')
        }
        formData.append('password', ruleForm.pass)
        signin_api(formData)
            .then(res => {
              if (res.data?.detail == 'ok') {
                const formData = new FormData();
                formData.append('grant_type', 'password');
                formData.append('username', ruleForm.email);
                formData.append('password', ruleForm.pass);
                formData.append('scope', '');
                formData.append('client_id', '');
                formData.append('client_secret', '');
                get_token(formData)
                    .then(res=>{
                      btn_loading.value = false
                      login_dialog.value = false
                      window.location.reload()
                      localStorage.setItem('access_token', res.data.access_token)
                      if(email){
                        localStorage.setItem('email', email)
                      }
                    })
                    .catch(()=>{
                      btn_loading.value = false
                    })
              } else {
                btn_loading.value = false
                ElMessage.error(res.data.detail)
              }
            })
            .catch(err => {
              if (err.response.data.detail) {
                ElMessage.error(parse_err(err.response.data.detail))
              } else {
                ElMessage.error(err.response.data.message)
              }
              btn_loading.value = false
            })
      }
    } else {
      btn_loading.value = false
    }
  })
  
  const parse_err = (detail) => {
    let ret = ''
    for (let i = 0; i < detail.email?.length ; i ++) {
      if (detail.email[i] == 'Email or Telephone does not exist') {
        ret = '账号或者密码错误'
      }
      if (detail.email[i] == 'User is not active') {
        ret = '账号未激活'
      }
    }
    for (let i = 0; i < detail.password?.length ; i ++) {
      if (detail.password[i] == 'Password is not match') {
        ret = '账号或者密码错误'
      }
    }
    return ret
  }
}

</script>

<style scoped lang="scss">
.form_content {
  padding: 2rem;
}

.form_favicon {
  position: absolute;
  top: 20px;
  display: flex;
  align-items: center;
  color: #000;

  img {
    width: 60px;
    margin-right: 12px;
  }
}

.form_container {
  position: absolute;
  top: 40px;
}

.page_background {
  min-height: 430px;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

:deep(.el-form-item__label) {
  font-size: 14px;
}

:deep(.el-form-item) {
  margin-bottom: 12px;
  text-align: left;
}

.sub_btn {
  width: 100%;
}

.a_signup {
  font-size: 13px;
  position: absolute;
  right: 0;
  top: 10px;
  color: #409eff;
  cursor: pointer;
}
.a_forget {
  font-size: 13px;
  color: #409eff;
  cursor: pointer;
}
.a_signup:hover {
  color: #3269a0;
}
.a_forget:hover {
  color: #3269a0;
}
.a_tip {
  font-size: 12px;
  color: #999;
}
.form_btns {
  margin-top: 32px;
}

</style>

