<template>
  <el-dialog v-model="dialogTableVisible" :before-close="close_visible"  width="800" title="反馈入口" :close-on-click-modal="false" :close-on-press-escape="false" :how-close="false">
    <div>
    <p class="text_1">
      是否过滤该新闻？
      <el-switch v-model="form_data.filtration" />
    </p>
      <el-form :model="form_data" ref="el_form" style="width: 100%">
        <div v-if="!form_data.filtration">
          <p class="text_1">
            修改标签
            <el-switch v-model="form_data.changes" />
          </p>
          <el-form-item v-if="form_data.changes" prop="change_list" :rules="[{ validator: changes_rule, trigger: 'change' }]">
            <div class="w-100">
              <p style="margin-bottom: 10px">
                <span style="display: inline-block;width: 20%;text-align: center">类别</span>
                <span style="display: inline-block;width: 35%;text-align: center">修改前</span>
                <span style="display: inline-block;width: 35%;text-align: center">修改后</span>
              </p>
              <p style="margin-bottom: 10px" v-for="(item,index) in form_data.change_list">
                <el-select v-model="item.class" @change="get_raw_option(item.class,index)" placeholder="Select" style="width: 20%">
                  <el-option
                      v-for="item in options.tab_type_option"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                  />
                </el-select>
                <el-select v-model="item.raw_label" @change="chenge_raw_label(item.raw_label,index)" placeholder="Select" style="width: 35%;margin-left: 10px">
                  <el-option
                      v-for="item in options[item.class]"
                      :key="item"
                      :label="item"
                      :value="item"
                  />
                </el-select>
                <el-select v-if="item.class === 'category'" v-model="item.new_label" placeholder="Select" style="width: 35%;margin-left: 10px">
                  <el-option
                      v-for="item in options.common_tag.filter(val=> val!== item.raw_label)"
                      :key="item"
                      :label="item"
                      :value="item"
                  />
                </el-select>
                <el-input v-else v-model="item.new_label" style="width: 35%;margin-left: 10px" placeholder="Please input" />
                <el-button @click="del_change_list(item.id)" :disabled="form_data.change_list.length <= 1" circle plain size="small" style="margin: 0 10px" type="danger">
                  <el-icon>
                    <Minus/>
                  </el-icon>
                </el-button>
              </p>
              <el-button @click="add_change_list" circle plain size="small" type="primary">
                <el-icon>
                  <Plus/>
                </el-icon>
              </el-button>
            </div>
          </el-form-item>
          <p class="text_1">
            删除标签【多选】
            <el-switch v-model="form_data.deletions" />
          </p>
          <el-form-item v-if="form_data.deletions" prop="delete_list" :rules="[{ validator: deletions_rule, trigger: 'change' }]">
            <div>
              <el-select v-model="form_data.delete_list" style="width: 712px" multiple placeholder="Select">
                <el-option
                    v-for="item in options.common_labels"
                    :key="item"
                    :label="item"
                    :value="'common_labels-' + item"
                >
                  <div class="d-flex" style="align-items: center">
                    <el-text type="danger">通用</el-text>
                    ·
                    <span>{{ item }}</span>
                  </div>
                </el-option>
                <el-option
                    v-for="item in options.drug_labels"
                    :key="item"
                    :label="item"
                    :value="'drug_labels-' + item"
                >
                  <div class="d-flex" style="align-items: center">
                    <el-text type="danger">药物</el-text>
                    ·
                    <span>{{ item }}</span>
                  </div>
                </el-option>
                <el-option
                    v-for="item in options.target_labels"
                    :key="item"
                    :label="item"
                    :value="'target_labels-' + item"
                >
                  <div class="d-flex" style="align-items: center">
                    <el-text type="danger">靶点</el-text>
                    ·
                    <span>{{ item }}</span>
                  </div>
                </el-option>
                <el-option
                    v-for="item in options.indication_labels"
                    :key="item"
                    :label="item"
                    :value="'indication_labels-' + item"
                >
                  <div class="d-flex" style="align-items: center">
                    <el-text type="danger">适应症</el-text>
                    ·
                    <span>{{ item }}</span>
                  </div>
                </el-option>
                <el-option
                    v-for="item in options.org_labels"
                    :key="item"
                    :label="item"
                    :value="'org_labels-' + item"
                >
                  <div class="d-flex" style="align-items: center">
                    <el-text type="danger">机构</el-text>
                    ·
                    <span>{{ item }}</span>
                  </div>
                </el-option>
              </el-select>
            </div>
          </el-form-item>
          <p class="text_1">
            新增标签
            <el-switch v-model="form_data.additions" />
          </p>
          <el-form-item v-if="form_data.additions" prop="add_list" :rules="[{ validator: additions_rule, trigger: 'submit' }]">
            <div>
              <div style="margin-bottom: 10px" v-for="(val,key) in form_data.add_list">
                <span>{{ tag_name[key] }}： </span>
                <el-tag
                    v-for="tag in val"
                    :key="tag"
                    style="margin-right: 5px"
                    closable
                    :disable-transitions="false"
                    @close="handleClose(tag,key)"
                >
                  {{ tag }}
                </el-tag>
                <el-input
                    v-if="visible[key]"
                    :ref="key + 'Ref'"
                    v-model="input_val[key]"
                    style="width: 80px"
                    size="small"
                    @keyup.enter="handleInputConfirm(key)"
                    @blur="handleInputConfirm(key)"
                />
                <el-button v-else class="button-new-tag" size="small" @click="showInput(key)">
                  + New Tag
                </el-button>
              </div>
            </div>
          </el-form-item>
        </div>
      </el-form>
    </div>
    <template v-slot:footer>
      <el-button @click="close_visible">取消</el-button>
      <el-button @click="submit_feedback" type="primary">确认</el-button>
    </template>
  </el-dialog>
</template>

<script setup>
import {defineProps, defineEmits, reactive, ref, nextTick} from "vue";
import {common_tag, tab_type_option, tag_name} from "@/components/common/feedback_option.js";
import {label_news} from "@/api/data.js";

const props = defineProps({
  dialogTableVisible: {
    type: Boolean
  },
  old_data: {
    type: Object,
  },
});
const emit = defineEmits(['update:feedback_visible']);

const dialogTableVisible = ref(true)
const el_form = ref(null)
const form_data = reactive({
  filtration: false,
  additions: true,
  add_list: {
    common_labels: [],
    drug_labels: [],
    target_labels: [],
    indication_labels: [],
    org_labels: [],
  },
  deletions: true,
  delete_list: [],
  changes: true,
  change_list: [
    {
      id: new Date().getTime(),
      class: "",
      raw_label: "",
      new_label: ""
    },
  ]
})
const visible = reactive({
  common_labels: false,
  drug_labels: false,
  target_labels: false,
  indication_labels: false,
  org_labels: false,
})
const input_val = reactive({
  common_labels: '',
  drug_labels: '',
  target_labels: '',
  indication_labels: '',
  org_labels: '',
})
const options = reactive({
  tab_type_option,
  common_tag,
  category: [props.old_data.category],
  common_labels: props.old_data.common_labels,
  drug_labels: props.old_data.drug_labels,
  target_labels: props.old_data.target_labels,
  indication_labels: props.old_data.indication_labels,
  org_labels: props.old_data.org_labels,
})

const get_raw_option = (type, index)=>{
  form_data.change_list[index].raw_label = ''
  form_data.change_list[index].new_label = ''
  if(type === 'category'){
    form_data.change_list[index].raw_label = options.category[0]
  }
}
const add_change_list = () =>{
  form_data.change_list.push({
    id: new Date().getTime(),
    class: "",
    raw_label: "",
    new_label: ""
  })
}
const del_change_list = (id) =>{
  // if(form_data.change_list.length > 1){
    form_data.change_list = form_data.change_list.filter(item => item.id !== id)
  // }
}
const common_labelsRef = ref()
const drug_labelsRef = ref()
const target_labelsRef = ref()
const indication_labelsRef = ref()
const org_labelsRef = ref()
const chenge_raw_label = (val,index)=>{
  form_data.change_list[index].new_label = val
}
const showInput = (keys) =>{
  Object.keys(visible).forEach(key => {
    if (key === keys){
      visible[key] = true
      nextTick(() => {
        eval(keys + 'Ref').value[0].focus()
      })
    } else {
      visible[key] = false
    }
  })
}
const handleClose = (tag, key) => {
  form_data.add_list[key].splice(form_data.add_list[key].indexOf(tag), 1)
}
const handleInputConfirm = (key) => {
  if (input_val[key]) {
    form_data.add_list[key].push(input_val[key])
  }
  visible[key] = false
  input_val[key] = ''
}
const close_visible = () =>{
  emit('send-visible', {
    visible: false,
  });
}
const changes_rule = (rule, value, callback)=>{
  let iscallback = true
  value.forEach((val) => {
    if(val.class && val.raw_label && val.new_label){

    } else {
      iscallback = false
    }
  });
  if (iscallback) {
    callback()
  } else {
    callback(new Error('请补全需要修改内容或关闭开关'))
  }
}
const deletions_rule = (rule, value, callback)=>{
  if (value.length) {
    callback()
  } else {
    callback(new Error('请选择需要删除的内容或关闭开关'))
  }
}
const additions_rule = (rule, value, callback)=>{
  if (form_data.add_list.common_labels + form_data.add_list.drug_labels + form_data.add_list.target_labels + form_data.add_list.indication_labels + form_data.add_list.org_labels) {
    callback()
  } else {
    callback(new Error('请添加标签内容或关闭开关'))
  }
}
const submit_feedback = () => {
  el_form.value.validate(valid => {
    if (valid) {
      const params = {
        news_id: props.old_data.id,
        is_filter: form_data.filtration,
      }
      if(form_data.filtration){
        params.changed_labels = params.deleted_labels = params.added_labels = []
      } else {
        if(form_data.changes){
          params.changed_labels = form_data.change_list
        } else {
          params.changed_labels = []
        }
        if(form_data.deletions){
          const deleted_labels = form_data.delete_list.map(item => {
            const [className, label] = item.split('-');
            return { class: className, label };
          })
          params.deleted_labels = deleted_labels
        } else {
          params.deleted_labels = []
        }
        if(form_data.additions){
          const added_labels = Object.entries(form_data.add_list).flatMap(([key, values]) => {
            return values.map(value => ({ class: key, label: value }));
          });
          params.added_labels = added_labels
        } else {
          params.added_labels = []
        }
      }
      if(params.added_labels.length + params.changed_labels.length + params.deleted_labels.length || params.is_filter){
        label_news(params)
            .then(res =>{
              close_visible()
              ElMessage({
                message: '反馈成功',
                type: 'success',
              })
            })
            .catch(err =>{
              ElMessage({
                message: '反馈失败',
                type: 'errop',
              })
            })
      } else {
        close_visible()
      }
    }
  })
}
</script>

<style scoped>
.text_1{
  font-size: 16px;
  margin: 10px 0;
}
:deep(.el-dialog__header){
  text-align: center;
}
</style>