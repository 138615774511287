<template>
  <App_frame tab_name="1":left_menu="!!is_login" :top_serch="false">
    <div class="content_1800">
        <div style="text-align: left">
          <div style="display: flex;flex-direction: column;justify-content: space-between">
            <h2>齐鲁情报数据库</h2>
            <h5>一次查询，即可获取相关药物、临床试验、机构资讯</h5>
            <el-select
                v-model="search_input"
                size="large"
                filterable
                remote
                reserve-keyword
                clearable
                @change="news_search"
                placeholder="Please input a keyword"
                :remote-method="remoteMethod"
                :loading="loading"
                style="max-width: 800px"
            >
              <el-option
                  v-for="item in options"
                  :key="item.label"
                  :value="item.cate + ' - ' + item.label"
                  :label="item.label"
              >
                <div class="d-flex" style="align-items: center">
                  <el-text type="danger">{{label_name[item.cate]}}</el-text>
                  ·
                  <span>{{ item.label }}</span>
                </div>
              </el-option>
            </el-select>
          </div>
          <div style="margin-top: 4vh">
            <p style="margin-bottom: 20px; font-size: 20px; font-weight: 600;">
              最新资讯
            </p>

            <ul id="container" style="padding: 0">
              <li
                  style="display: flex;margin: 20px 0"
                  class="space_between hand-cursor list-item"
                  v-for="item in table_data"
                  :key="item.id"
              >
                <div style="display: flex;width: 70%; align-items: center">
                  <el-tag type="info" :color="tag_type(item.category)" effect="dark" style="min-width: 80px">{{category_name(item.category)}}</el-tag>
                  <div class="w-100">
                    <div class="w-100 flex_align_center">
                      <p @click="href_url(item.page_url)" class="ellipsis hand-cursor" style="margin: 0 10px;" :title="item.title.split('%%%%')[0]">{{item.title.split('%%%%')[1]}}</p>
                      <el-tag size="small" v-for="label in item.common_labels" :key="label" :title="label" type="success" effect="light">{{label}}</el-tag>
                    </div>
<!--                    <p style="margin: 0 10px;">-->
<!--                      <el-tag size="small" v-for="label in item.drug_labels.slice(0, 2)" :key="label" :title="label" type="primary" effect="light">{{label}}</el-tag>-->
<!--                      <el-tag size="small" v-for="label in item.org_labels.slice(0, 2)" :key="label" :title="label" type="primary" effect="light">{{label}}</el-tag>-->
<!--                      <el-tag size="small" v-for="label in item.target_labels.slice(0, 2)" :key="label" :title="label" type="primary" effect="light">{{label}}</el-tag>-->
<!--                      <el-tag size="small" v-for="label in item.indication_labels.slice(0, 2)" :key="label" :title="label" type="primary" effect="light">{{label}}</el-tag>-->
<!--                    </p>-->
                  </div>
                </div>
                <div class="space_between" style="width: 20%;display: flex;align-items: start">
<!--                  <el-button style="max-width: 50%;" link>-->
<!--                    {{item.website_name}}-->
<!--                  </el-button>-->
                  <el-text style="max-width: 50%;">{{item.website_name}}</el-text>
                  <el-text style="width: 30%;">{{time_from_now(item.date)}}</el-text>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    <el-dialog
        v-model="login_dialog"
        class="gpt_dialog"
        width="500"
    >
      <User_dialog />
    </el-dialog>
  </App_frame>
</template>

<script setup>
import App_frame from "@/components/layout/app_frame.vue";
import {onMounted, reactive, ref} from "vue";
import {get_recent_group, labels_fuzz, news_filter} from "@/api/data";
import {dayjs} from "element-plus";
import relativeTime from 'dayjs/plugin/relativeTime';
import {category_list, label_name} from "../components/common/results_data.js";
import {storeToRefs} from "pinia";
import useStatusStore from "@/store/index.js";
import router from "@/router/index.js";

const store = useStatusStore()
const search_input = ref('')
const table_data = ref([])
const loading = ref(false)
const options = ref([])
const { login_dialog, user_tab } = storeToRefs(store)
const is_login = localStorage.getItem('access_token');

const submit_list = reactive({
  date_type: 'any',
  date_list: [],
  category_list: [],
  common_list: [],
  drug_list: [],
  target_list: [],
  indication_list: [],
  org_list: [],
})
const remoteMethod = (query) => {
  if(is_login){
    if (query) {
      loading.value = true
      labels_fuzz(query)
          .then(res =>{
            loading.value = false
            options.value = res.data.labels
          })
    } else {
      options.value = []
    }
  } else {
    sign_in()
  }
}
const sign_in = ()=>{
  login_dialog.value = true
  user_tab.value = 'sign_in'
}

const tag_type = (tag)=>{
  let type = '#909399'
  if(tag === '临床'){
    type = '#049b39'
  } else if (tag === '会议'){
    type = '#d5ac1c'
  } else if (tag === '市场准入'){
    type = '#c93e10'
  } else if (tag === '政策与法规'){
    type = '#1290d2'
  } else if (tag === '早研'){
    type = '#ab0d6c'
  } else if (tag === '总结报告'){
    type = '#860da8'
  } else if (tag === '投融资'){
    type = '#2619a8'
  } else if (tag === '人事变动'){
    type = '#691919'
  }
  return type
}
const time_from_now = (time) =>{
  dayjs.extend(relativeTime)

  return dayjs(time).fromNow()
}
const href_url = (url)=>{
  if(is_login){
    window.open(url, '_blank');
  } else {
    sign_in()
  }
}
const router_push = command => {
  router.push(command)
}
const news_search = (data)=>{
  // console.log(data)
  if(data){
    router_push({ name: 'advisory', query: { search: data } })
  }
}
const category_name = (val) => {
  if(category_list[val]){
    return category_list[val]
  } else {
    return val
  }
}

onMounted(()=>{
    get_recent_group(1,10)
        .then(res=>{
          table_data.value = res.data
          // console.log(res)
        })
})


</script>
<style scoped>
.content_1800 {
  max-width: 1400px;
  margin: 0 auto;
  padding: 50px;
  height: calc(100vh - 60px);
  overflow: hidden;
}
.flex_align_center {
  display: flex;
  align-items: center;
}
.space_between{
  justify-content: space-between;
}
.el-tag{
  margin: 0 2px;
  max-width: 100px;
  cursor: pointer;

}
.ellipsis{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.el-tag :deep(.el-tag__content){
  overflow: hidden;
  text-overflow: ellipsis;
}
.hand-cursor {
  cursor: pointer;
}
:deep(.el-button>span){
  width: 100%;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.el-text{
  align-self: normal;
}
</style>
