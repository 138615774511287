<template>
  <div v-if="is_login">
    <el-dropdown placement="bottom">
      <el-avatar :size="40">
        <svg_temp name="avatar" width="70px" height="70px"></svg_temp>
      </el-avatar>
      <template #dropdown>
        <el-dropdown-menu>
          <el-dropdown-item @click="signout">登出</el-dropdown-item>
        </el-dropdown-menu>
      </template>
    </el-dropdown>
  </div>
  <div v-else>
    <el-button v-if="!if_ldap" @click="sign_up" type="primary">注册</el-button>
    <el-button @click="sign_in">登录</el-button>
  </div>
  <el-dialog
      v-model="login_dialog"
      class="gpt_dialog"
      width="500"
  >
    <User_dialog />
  </el-dialog>
</template>

<script setup>
import {ref, defineProps} from 'vue';
import {ElMessage} from "element-plus";
import {signout_api} from "@/api/user";
import useStatusStore from "@/store";
import {storeToRefs} from "pinia";
import User_dialog from "@/components/users/user_dialog.vue";
const store = useStatusStore()
const { login_dialog, user_tab } = storeToRefs(store)
// const login_dialog = ref(mapState(['login_dialog']))
// const user_tab = ref(mapState(['user_tab']))
const is_login = localStorage.getItem('access_token');
const if_ldap = ref(false)

const loadApi = async () => {
  if (import.meta.env.VITE_APP_ENV === 'ldap') {
    if_ldap.value = true
  }
}
loadApi()
const props = defineProps({
  avatar_url: {
    type: String,
  },
})
const signout = () => {
  if(if_ldap){
    localStorage.removeItem('cs_csrf_token')
    localStorage.removeItem('access_token')
    ElMessage({
      message: '登出成功',
      type: 'success'
    })
    window.location.reload()
  } else {
    signout_api().then(() => {
      localStorage.removeItem('cs_csrf_token')
      localStorage.removeItem('access_token')
      ElMessage({
        message: '登出成功',
        type: 'success'
      })
      window.location.reload()
      // window.location.reload()
    }).catch(() => {
    })
  }
}
const sign_in = ()=>{
  login_dialog.value = true
  user_tab.value = 'sign_in'
}
const sign_up = ()=>{
  login_dialog.value = true
  user_tab.value = 'sign_up'
}
</script>

<style scoped>

</style>